import React from 'react';
import dayjs from 'dayjs';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import { MenuLayout } from '../../components/menu-layout.component';
import { getFullNameKanji, getFullNameKatakana } from '../../utils/patient-info.format';
import { usePatients } from '../../hooks/use-patients.hook';

export default function RegisteredInfo() {
    const { t } = useI18next();
    const { patients } = usePatients();
    
    return <MenuLayout className="dashboard">
        <div className="section section--registered-info animateIn">
            <div className="container">
                <div className="section--registered-info__heading">
                    <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.16064 17.5572V20.0037C2.16064 21.5867 3.4631 22.834 5.11622 22.834C5.16632 22.834 5.21641 22.834 5.26651 22.834L6.86953 22.5941V25.0886C6.86953 25.3764 7.01982 25.6162 7.2202 25.8081C7.37048 25.952 7.62095 26 7.82133 26C7.87143 26 7.97162 26 8.02171 26L19.1928 23.6974C19.6437 23.6015 19.9442 23.2177 19.9442 22.786V17.0775C21.8979 15.2066 23 12.6642 23 9.97786C23 4.46126 18.3412 0 12.5803 0C11.0274 0 9.52455 0.335793 8.1219 0.95941C4.46499 2.59041 2.11055 6.14022 2.11055 10.0258V11.321L0.307143 14.631C-0.0936139 15.3506 -0.0936142 16.1181 0.257048 16.6937C0.707899 17.1734 1.35913 17.5092 2.16064 17.5572ZM2.06045 15.3985L4.01414 11.8487C4.06424 11.7048 4.11433 11.5609 4.11433 11.417V9.92989C4.11433 6.76384 6.06802 3.88561 9.0236 2.54244C10.1758 2.01476 11.378 1.77491 12.6304 1.77491C17.3393 1.77491 21.1465 5.42067 21.1465 9.92989C21.1465 12.1845 20.1446 14.3911 18.3913 15.9262C18.1909 16.1181 18.0907 16.3579 18.0907 16.5978V21.9225L8.82323 23.8413V21.4428C8.82323 21.155 8.72303 20.9151 8.47256 20.7232C8.27218 20.5793 8.07181 20.4834 7.82133 20.4834C7.77124 20.4834 7.72114 20.4834 7.67105 20.4834L5.06613 20.9631C4.51509 20.9151 4.11433 20.4834 4.11433 20.0037V16.6458C4.11433 16.1181 3.66348 15.7343 3.16254 15.7343H2.26083C2.11055 15.7343 2.06045 15.7343 2.01036 15.6863C2.01036 15.5904 2.01036 15.5424 2.06045 15.3985Z" fill="#2C3B4A"/>
                    </svg>
                    <h1 className="t-h2 t-grey9">{t('Registered info')}</h1>
                </div>
                {patients && patients.length > 0 ? (
                    <div className="section--registered-info__tabs">
                        {patients.map(patient => (
                            <div key={patient.id} className="section--registered-info__tab">
                                <h2 className="section--registered-info__tab__heading t-bold">{getFullNameKanji(patient)}</h2>
                                <div className="section--registered-info__tab__grid section--registered-info__tab__grid--dual-columns">
                                    <div className="section--registered-info__tab__content">
                                        <label className="t-sans t-bold t-grey9">{t('フリガナ')}</label>
                                        <p className="t-sans t-grey9">{getFullNameKatakana(patient)}</p>
                                    </div>
                                    <div className="section--registered-info__tab__content">
                                        <label className="t-sans t-bold t-grey9">{t('Gender')}</label>
                                        <p className="t-sans t-grey9">{t(patient.gender || 'N/A')}</p>
                                    </div>
                                    <div className="section--registered-info__tab__content">
                                        <label className="t-sans t-bold t-grey9">{t('Date of birth')}</label>
                                        <p className="t-sans t-grey9">{patient.dateOfBirth ? dayjs(patient.dateOfBirth).format('YYYY年MM月DD日') : 'N/A'}</p>
                                    </div>
                                </div>
                                <div className="section--registered-info__tab__grid fixed-mt">
                                    <div className="section--registered-info__tab__content">
                                        <label className="t-sans t-bold t-grey9">{t('Phone number')}</label>
                                        <p className="t-sans t-grey9">{patient?.phoneNumber || 'N/A' }</p>
                                    </div>
                                    <div className="section--registered-info__tab__content">
                                        <label className="t-sans t-bold t-grey9">{t('Email address')}</label>
                                        <p className="t-sans t-grey9">{patient?.emailAddress || 'N/A' }</p>
                                    </div>
                                    <div className="section--registered-info__tab__content">
                                        <label className="t-sans t-bold t-grey9">{t('Address')}</label>
                                        <p className="t-sans t-grey9">
                                            {patient?.prefectureName || 'N/A'}
                                            {` ${patient?.city || ''}`}
                                            {` ${patient?.addressOne || ''}`}
                                            {` ${patient?.addressTwo || ''}`}
                                            {` ${patient?.postalCode || ''}`}
                                        </p>
                                    </div>
                                </div>
                                <div className="section--registered-info__tab__button">
                                    <Link className="hap-button size-small" to={`/dashboard/edit-info/?id=${patient.id}&type=registered`}>
                                    <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.00281954 6.36149L0.0301599 2.1567C0.0419936 0.374552 2.02846 -0.634949 3.39998 0.444183L6.17236 2.62523C7.30152 3.51376 7.26642 5.28538 6.12136 6.11524L3.34124 8.2191C1.91749 9.29655 -0.0861385 8.17144 0.00286537 6.36106L0.00281954 6.36149ZM5.55334 5.31356L2.77322 7.41741C1.98648 8.01328 0.903095 7.37271 0.964312 6.39309L0.992061 2.16336C0.995733 1.18416 2.08403 0.654882 2.81779 1.23476L5.59017 3.4158C6.20594 3.90161 6.17779 4.86101 5.55345 5.31346L5.55334 5.31356Z" fill="white"/>
                                    </svg> {t('Update')}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>no patient found</p>
                )}
            </div>
        </div>
    </MenuLayout>
}