import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientsAsync } from "../states/patients.slice";

export const usePatients = () => {
    const dispatch = useDispatch();

    const { patients } = useSelector(s => s.patients);
    const { loggedIn } = useSelector(s => s.authentication);

    useEffect(() => {
        if (loggedIn) {
            dispatch(fetchPatientsAsync());
        }
    }, [ loggedIn ]);

    return { patients };
};